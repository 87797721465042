import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'az-root',
  encapsulation: ViewEncapsulation.None,
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor(public auth: AuthService) { }
  
  OnEditorEvent(type, targetID)
  {
    switch (type) {
      case "DocumentFullyLoaded":
        console.log('docloaded')
      case "DocumentFullyRendered":
        console.log('docRenderd')
      break;
    }
  }
}
